<template>
  <v-system-bar
    v-if="model"
    app
    color="#262626"
    class="px-4"
    dark
    height="58"
  >
    <!-- <a
      class="d-flex align-center"
      href="https://vuetifyjs.com/?ref=vtyd-pro-demo-system-bar"
      target="_blank"
    >
      <v-img
        alt="Vuetify Logo"
        class="mr-2"
        contain
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        width="32"
      />

      <v-img
        alt="Vuetify Name"
        class="hidden-xs-only"
        contain
        width="96"
        src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
      />
    </a> -->

    <v-spacer />

    <!-- <v-btn
      class="text-capitalize subtitle-2 mr-6"
      color="success"
      href="https://wa.me/+916383906364?text=Hello%2C%20I%27m%20looking%20for%20an%20info%20at%20Hi5Magic"
      min-width="112"
    > -->
    <v-btn
      class="text-capitalize subtitle-2 mr-6"
      color="success"
      href="https://wa.me/+916383906364?text=Hello%2C%20I%27m%20looking%20for%20an%20info%20at%20Hi5Magic"
      target="_blank"
    >
      <v-icon
        color="white"
      >
        mdi-whatsapp
      </v-icon>
      Chat With Us
    </v-btn>

    <v-divider
      class="ml-6 mr-3"
      vertical
    />

    <v-btn
      class="text-capitalize grey--text mr-n2 mr-sm-n1"
      small
      text
      @click="model = false"
    >
      <v-icon
        color="white"
        :left="$vuetify.breakpoint.smAndUp"
      >
        mdi-close
      </v-icon>

      <span class="hidden-xs-only">
        Close
      </span>
    </v-btn>
  </v-system-bar>
</template>

<script>
  export default {
    name: 'HomeSystemBar',

    data: () => ({
      model: true,
    }),
  }
</script>
